import axios from 'axios'

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + match[2] + match[3]
  }
  return null
}

export const getCityState = (ip = '') => {
  return axios
    .get('https://pro.ip-api.com/json/?key=g9gkAqhucbYIbxb')
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log('getCityState GET catch')
      console.log(err)
      return { city: '', region: '' }
    })
}

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const submitApplication = (type, data, src) => {
  /*
        Types + Val Options:
            19 -> Life
                use_tobacco: Y,N
                gender: M,F
                insurance_type: final_expense, term
                insurance_amount: 50000
            21 -> medicare
                gender: M,F
                use_tobacco: Y,N
                age: Decimal 2
            27 -> health
                gender: Male, Female
                age: Int
                use_tobacco: Yes, No
                household_size: 1
                currently_insured: Yes, No

        Global Options:
            DOB: MM/DD/YYYY
            dob_month: Varchar
            dob_day: Varchar
            dob_year: Varchar
    */
  let phone = formatPhoneNumber(data.phone)
  let zip = data.zip.replace(/\D/g, '').slice(0, 5)

  if (zip.length !== 5) {
    return Promise.reject(new Error('Zip code must be 5 digits'))
  }
  if (phone.length !== 10) {
    return Promise.reject(new Error('Phone number must include your area code'))
  }
  if (!validateEmail(data.email)) {
    return Promise.reject(new Error('Please provide a valid email address'))
  }

  // Format the data how we need it
  let postData = {
    TYPE: 21,
    first_name: data.first,
    last_name: data.last,
    email: data.email,
    phone_1: phone,
    address_1: data.short ? '123 main st' : data.address,
    zip_code: zip,
    gender: 'M',
    dob_month: data.month ? data.month : 1,
    dob_day: data.day ? data.day : 1,
    dob_year: data.year ? data.year : '1953',
    Age: data.age ? data.age.toFixed(2) : 65,
    use_tobacco: 'N',
    LeadiD: data.leadid,
    phone_last_4: phone.slice(-4),
    height_ft: '3',
    height_in: '0',
    weight: '99',
    household_size: '1',
    estimated_household_income: '40000',
    currently_insured: 'No',
    tcpa_site: 'https://quotes.medicarecompanion.org/',
    landing_page: 'https://quotes.medicarecompanion.org/',
    Trusted_Form_Token:
      data.Trusted_Form_Token && data.Trusted_Form_Token.split('.com/')[1],
    Trusted_Form_Cert_URL: data.Trusted_Form_Cert_URL,
    ...(data.Sub_ID && { Sub_ID: data.Sub_ID }),
    ...(data.Pub_ID && { Pub_ID: data.Pub_ID }),
    ...(data.city && { city: data.city }),
    ...(data.state && { state: data.state }),
    ...(data.insurance_type && { insurance_type: data.insurance_type }),
    ...(data.insurance_amount && { insurance_amount: data.insurance_amount }),
    ...(data.term_length && { term_length: data.term_length }),
  }

  // Attach SRC if we have a value in store
  if (src !== '') {
    if (type === 'health' && !data.other) {
      postData['SRC'] = src.replace('-Med', '-Health')
    } else if (type === 'medicare' && !data.other) {
      // Nothing, it should stay as `-Med`
      postData['SRC'] = src
    } else {
      postData['SRC'] = src.replace('-Med', '-Life')
    }
  }

  if (type === 'health' && !data.other) {
    postData['TYPE'] = 27
    postData['gender'] = data.gender === 'M' ? 'Male' : 'Female'
    postData['Age'] = Math.round(data.age)
    postData['dob_month'] = data.month.length === 1 ? '0' + data.month : data.month
    postData['dob_day'] = data.day.length === 1 ? '0' + data.day : data.day
    postData['estimated_household_income'] = parseInt(data.income)
    postData['household_size'] = data.household
    postData['weight'] = data.weight
    postData['height_ft'] = data.ft
    postData['height_in'] = data.in
    postData['use_tobacco'] = data.tobacco
  } else if (type === 'medicare' && !data.other) {
    postData['TYPE'] = data.short ? 36 : 21
    postData['gender'] = data.gender === 'M' ? 'M' : 'F'
    postData['currently_insured'] = data.existing
  } else {
    postData['TYPE'] = 19
    postData['gender'] = data.gender === 'M' ? 'M' : 'F'
    postData['dob_month'] = parseInt(data.month)
    postData['dob_day'] = parseInt(data.day)
    postData['dob_year'] = parseInt(data.year)
    postData['Age'] = Math.round(data.age)
    // delete postData['SRC'];

    if (type === 'medicare') {
      postData['insurance_type'] = 'final_expense'
      postData['insurance_amount'] = 20000
      postData['use_tobacco'] = 'No'
      postData['term_length'] = 'null'
    }
    if (type === 'health') {
      postData['insurance_type'] = data.overfiftyfive ? 'final_expense' : 'term'
      postData['insurance_amount'] = data.overfiftyfive ? 20000 : 500000
      postData['use_tobacco'] = data.tobacco
      postData['term_length'] = '15'
    }
  }

  if (data.short && type !== 'health') {
    postData['Age'] = Math.round(data.age)
    postData['gender'] = 'M'
    postData['dob_year'] = parseInt(data.year)
  }

  if (data.short && type !== 'life' && type !== 'health' && !data.other) {
    postData['TYPE'] = 36
    postData['Age'] = Math.round(data.age)
    postData['gender'] = 'Male'
    postData['dob_year'] = parseInt(data.year)
  }

  // Bypass the API call and just return the data format
  // Later we should make a param in the endpoint that
  // allows us to overwrite the test value
  // if (process.env.NODE_ENV === 'development') {
  //   return Promise.resolve(postData)
  // }

  const submitEndpointUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://customer-dev.hivehq.co/v1/bobderdoo-lead-creation'
      : 'https://customer.hivehq.co/v1/bobderdoo-lead-creation'

  return axios
    .post(submitEndpointUrl, postData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log('bobderdoo POST catch')
      console.error(error)
      return Promise.reject(error)
    })
}
