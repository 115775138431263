/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Redux setup
import wrapWithProvider from './src/store/wrap-with-provider'

export const wrapRootElement = wrapWithProvider

// Initial Client Render of Gatsby
// export const onInitialClientRender = () => {
//     // Stupid jQuery hack for now
//     window.initiateJquery();
// }
