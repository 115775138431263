import React from 'react'
import { Provider } from 'react-redux'
import queryString from 'query-string'

import createStore from './create-store'

const initScript = (store) => {
  try {
    const qs = queryString.parse(window.location.search)
    if (qs.type) {
      store.dispatch({ type: 'UPDATE_TYPE', payload: qs.type })
    }
  } catch (err) {
    // if fail do nothing
  }
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  initScript(store)
  return <Provider store={store}>{element}</Provider>
}
